import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { ab_nv } from "src/app/pages/apply/sedPersForm";
import { setFormState } from "src/app/shared/functions";

@Component({
  selector: "app-arbeitsbeginn-status",
  templateUrl: "./arbeitsbeginn-status.component.html",
  styleUrls: ["./arbeitsbeginn-status.component.scss"],
})
export class ArbeitsbeginnStatusComponent implements OnInit {
  constructor() {}

  @Input()
  persForm!: UntypedFormGroup;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isChanged: Function = (key: string, subFormGroup: string = "") => false;

  arbeitsBeginnStatus!: UntypedFormGroup;

  arbeitsBeginnAdditional!: UntypedFormGroup;

  @Input()
  submitAttempt: boolean = false;

  @Output()
  isUploading = new EventEmitter<boolean>();

  @Input()
  @HostBinding("class.width-100")
  modalDesign: boolean = false;

  /**
   * If the component is used in the customer facing view or the internal view
   */
  @Input()
  isCustomerFacing: boolean = true;

  @Input()
  onlyPreview: boolean = false;

  arbeitsBeginnStatusDataStructure = ab_nv;

  arbeitsbeginnSubscription?: Subscription;

  persFormSubscription?: Subscription;

  lastFormValue: any;

  ngOnDestroy(): void {
    if (this.arbeitsbeginnSubscription) {
      this.arbeitsbeginnSubscription?.unsubscribe();
    }
    if (this.persFormSubscription) {
      this.persFormSubscription?.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.arbeitsBeginnStatus = this.persForm.controls
      .arbeitsbeginn_status as UntypedFormGroup;

    this.arbeitsBeginnAdditional = this.persForm.controls
      .arbeitsbeginn_status_additional as UntypedFormGroup;

    this.arbeitsbeginnSubscription =
      this.persForm.controls.arbeitsbeginn_status.valueChanges.subscribe(
        (value) => {
          this.intersectStatusBeiArbeitsbeginn(value);
        }
      );

    if (!this.onlyPreview) {
      this.persFormSubscription = this.startPersFormSubscription();
      this.enableDisableFormFieldsByRules();

      this.intersectStatusBeiArbeitsbeginn(
        this.persForm.controls.arbeitsbeginn_status.value
      );
    }
  }

  /**
   * Enables/disables inputs based on custom rules
   * from Topteam
   * @returns Subscription
   */
  startPersFormSubscription(): Subscription {
    return this.persForm.valueChanges.subscribe(() => {
      this.enableDisableFormFieldsByRules();
    });
  }

  enableDisableFormFieldsByRules(): void {
    const changed = this.detectFormChanges(this.persForm, this.lastFormValue);

    const hasResidencePermit: boolean = this.persForm.value.aufenthaltstitel;
    setFormState(
      this.persForm.controls.aufenthaltstitel_nachweis,
      hasResidencePermit
    );

    const hasBusinessCertificate: boolean = this.persForm.value.gewerbeschein;
    setFormState(
      this.persForm.controls.gewerbeschein_nachweis,
      hasBusinessCertificate
    );

    const isSelfEmployed: boolean =
      this.persForm.value.arbeitsbeginn_status.Selbststaendig;

    setFormState(
      this.persForm.controls.existiert_hauptarbeitgeber,
      !isSelfEmployed
    );

    const hasPrimaryEmployer: boolean =
      this.persForm.value.existiert_hauptarbeitgeber;
    const taxClass: number = this.persForm.value.steuerklasse;

    if (hasPrimaryEmployer && taxClass < 6) {
      this.persForm.controls.steuerklasse.setValue(6, {
        emitEvent: true,
      });
    } else if (!hasPrimaryEmployer && taxClass === 6) {
      this.persForm.controls.steuerklasse.setValue(1, {
        emitEvent: true,
      });
    }

    // reset arbeitsbeginn_status if existiert_hauptarbeitgeber is changed, so validation is triggered again
    if (changed.includes("existiert_hauptarbeitgeber")) {
      this.persForm.controls.arbeitsbeginn_status.updateValueAndValidity();
    }
  }

  private detectFormChanges(form: UntypedFormGroup, lastFormValue: any) {
    const changedKeys = Object.keys(form.value).filter((key) => {
      return form.value[key] !== lastFormValue?.[key];
    });

    this.lastFormValue = { ...form.value };

    return changedKeys;
  }

  /**
   * Returns the filtered datastructure to render the arbeits
   * beginn status buttons
   */
  get filteredArbeitsBeginnStatus(): {
    formControlName: string;
    label: string;
    validWith: string[];
    needsCert: boolean;
  }[] {
    return Object.values(this.arbeitsBeginnStatusDataStructure).filter(
      (el) =>
        !(this.persForm.controls.arbeitsbeginn_status as UntypedFormGroup)
          .controls[el.formControlName].disabled ||
        (this.onlyPreview && this.arbeitsBeginnStatus.value[el.formControlName])
    );
  }

  /**
   * Returns the filtered datastructure to render the arbeitsbeginn
   * status certificate/additional information for each status button
   */
  get filteredArbeitsBeginnStatusAdditional(): {
    formControlName: string;
    label: string;
    validWith: string[];
    needsCert: boolean;
  }[] {
    return this.filteredArbeitsBeginnStatus.filter(
      (el) =>
        (el.needsCert &&
          !this.arbeitsBeginnAdditional.controls[`${el.formControlName}_fach`]
            .disabled) ||
        (this.onlyPreview &&
          this.arbeitsBeginnAdditional.value[`${el.formControlName}_fach`])
    );
  }

  /**
   * Intersects the possible status buttons, because not all buttons
   * valid in every combination
   * @param value only useable in this context - thus look at the code
   */
  intersectStatusBeiArbeitsbeginn(value: any) {
    let enabled: string[] = [];

    // intersect of enabled
    for (const k in value) {
      if (value[k] === true) {
        if (!enabled.length) {
          enabled.push(...this.arbeitsBeginnStatusDataStructure[k].validWith);
          enabled.push(k);
        } else {
          enabled = enabled.filter((val) =>
            this.arbeitsBeginnStatusDataStructure[k].validWith
              .concat(k)
              .includes(val)
          );
        }
      }
    }
    // make unique
    enabled = enabled.filter((v, i, a) => a.indexOf(v) === i);

    if (!enabled.length) {
      enabled = Object.keys(this.arbeitsBeginnStatusDataStructure);
    }

    // enable/disable form controls based on intersection
    for (const k of Object.keys(this.arbeitsBeginnStatusDataStructure)) {
      if (enabled.includes(k)) {
        this.arbeitsBeginnStatus.controls[k].enable({ emitEvent: false });
      } else {
        this.arbeitsBeginnStatus.controls[k].disable({ emitEvent: false });
      }

      if (
        this.arbeitsBeginnStatusDataStructure[k].needsCert &&
        this.arbeitsBeginnStatus.controls[k].enabled &&
        this.arbeitsBeginnStatus.controls[k].value === true
      ) {
        this.arbeitsBeginnAdditional.controls[`${k}_fach`].enable({
          emitEvent: false,
        });
        this.arbeitsBeginnAdditional.controls[`${k}_nachweis`].enable({
          emitEvent: false,
        });
      } else if (this.arbeitsBeginnStatusDataStructure[k].needsCert) {
        this.arbeitsBeginnAdditional.controls[`${k}_fach`].disable({
          emitEvent: false,
        });
        this.arbeitsBeginnAdditional.controls[`${k}_nachweis`].disable({
          emitEvent: false,
        });
      }
      if (this.arbeitsBeginnStatusDataStructure[k].needsValidUntil) {
        if (this.arbeitsBeginnStatus.controls[k].value === true) {
          this.arbeitsBeginnAdditional.controls[`${k}_gueltig_bis`].enable({
            emitEvent: false,
          });
        } else {
          this.arbeitsBeginnAdditional.controls[`${k}_gueltig_bis`].disable({
            emitEvent: false,
          });
        }
      }
    }
  }
}
