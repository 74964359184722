<div
  class="mehr-infos"
  [ngClass]="{ 'white-bg': whiteBg }"
  [ngStyle]="{paddingTop}"
>
  <app-svgs
    type="close"
    color="primary"
    class="close-btn"
    *ngIf="closeBtn"
    (click)="close.emit(true)"
  ></app-svgs>
  <h1 *ngIf="showTitleAndAnforderung && project?.titel && showTitle">
    {{ project?.titel }}
  </h1>

  <div class="status" *ngIf="status">
    <div
      class="badge flex-center"
      [ngStyle]="{
        backgroundColor: ApplicationStatusStringArbeitskraft[status].iconBg
      }"
    >
      <app-svgs
        [type]="ApplicationStatusStringArbeitskraft[status].icon"
        color="white"
      ></app-svgs>
    </div>
    {{ ApplicationStatusStringArbeitskraft[status].label }}
  </div>

  <table>
    <!-- <tr *ngIf="showTitleAndAnforderung">
      <td><h5>TODO Anforderung</h5></td>
      <td>
        <div class="two-cols">
          <app-svgs type="checkmark-circle" color="secondary"></app-svgs>
          <p class="secondary">TODO Erfüllt</p>
        </div>
      </td>
    </tr> -->
    <tr *ngIf="project?.branche">
      <td><h5>Branche</h5></td>
      <td>
        <p>{{ project?.branche }}</p>
      </td>
    </tr>
    <tr *ngIf="project?.zeitraum_von">
      <td><h5>Zeitraum</h5></td>
      <!-- TODO dayname rausnehmen in pipe -->
      <td>
        <p>
          {{ project?.zeitraum_von | formatTwoDates : project?.zeitraum_bis }}
        </p>
      </td>
    </tr>
    <tr *ngIf="(project?.zeiten || []).length">
      <td>
        <h5>
          Einsatzzeit{{ (project?.zeiten || []).length > 1 ? "en" : "" }}
          ca.
        </h5>
      </td>
      <td>
        <p>
          {{ project?.zeitraum_von | datesToDaynames : project?.zeitraum_bis }}
        </p>
        <p *ngFor="let zeit of project?.zeiten">
          {{ zeit.zeit_von }} Uhr - {{ zeit.zeit_bis }} Uhr
        </p>
      </td>
    </tr>
    <tr *ngIf="project?.honorar">
      <td><h5>Honorar</h5></td>
      <td>
        <p *ngIf="project?.honorar?.honorar_auf_anfrage">Auf Anfrage</p>
        <p *ngIf="!project?.honorar?.honorar_auf_anfrage">
          {{ project?.honorar?.honorar_eur }} € pro Stunde
        </p>
      </td>
    </tr>
  </table>

  <div *ngIf="project?.aktionsinhalt && project?.aktionsinhalt != ''">
    <h5 class="h5-underline">Aktionsinhalt</h5>
    <p [innerHtml]="project?.aktionsinhalt"></p>
  </div>
  <div *ngIf="project?.anforderungsprofil && project?.anforderungsprofil != ''">
    <h5 class="h5-underline">Anforderungsprofil</h5>
    <p [innerHtml]="project?.anforderungsprofil"></p>
  </div>
  <div *ngIf="project?.aktions_standorte && project?.aktions_standorte != ''">
    <h5 class="h5-underline">Aktions-Standorte</h5>
    <p [innerHtml]="project?.aktions_standorte"></p>
  </div>
  <!-- TODO: Ist das hier der Projektverantwortliche? -->
  <div>
    <h5 class="h5-underline">Ansprechperson</h5>
    <div class="p-style">
      Topteam GmbH<br />
      Körnerstraße 48<br />
      D-63067 Offenbach am Main<br />
      <br />
      <div *ngIf="project?.ansprechperson?.user?.profile">
        vertreten durch:<br /><br />
        {{ project?.ansprechperson?.user?.profile?.vorname }}
        {{ project?.ansprechperson?.user?.profile?.nachname }}<br />
      </div>
      <br />
      <div class="flex gap-16 margin-bottom-36">
        <app-svgs type="email" color="primary"></app-svgs>
        <a
          href="mailto:{{
            project?.ansprechperson?.user?.email ||
              'info@connect.topteam-gmbh.com'
          }}"
          >{{
            project?.ansprechperson?.user?.email ||
              "info@connect.topteam-gmbh.com"
          }}</a
        >
      </div>
      <br />
    </div>
  </div>
  <app-button
    [ngClass]="{
      deactivated:
        auth.currentUser?.activated === false || !project?.bewerbung_moeglich
    }"
    *ngIf="showBewerbenButton"
    [disabled]="
      auth.currentUser?.activated === false || !project?.bewerbung_moeglich
    "
    background="white"
    [routerLink]="'/jobs/apply/' + project?.id"
    class="sticky-button"
    >{{
      project?.bewerbung_moeglich
        ? "Jetzt bewerben"
        : "Derzeit keine Bewerbung möglich"
    }}</app-button
  >
</div>
