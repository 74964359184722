import { animate, style, transition, trigger } from "@angular/animations";
import { Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Confirm, Notify } from "notiflix";
import { pairwise, startWith, Subscription } from "rxjs";
import { DataService } from "src/app/data.service";

import {
  geschlecht,
  guertellaenge,
  haarfarben,
  hoechster_allgemeiner_schulabschluss,
  hoechster_beruflicher_abschluss,
  hose_rock_groessen,
  jacke_sacko_groessen,
  jeanslaenge,
  jeansweite,
  kragenweite,
  schuhgroesse,
  shirtArr,
  shirtToggles,
  sprachNiveau,
  steuerKlassen,
} from "@ttc_types/datasets";
import { gesetzlicheKK } from "@ttc_types/gesetzlicheKK";
import { konfessionen } from "@ttc_types/konfessionen";
import { privateKK } from "@ttc_types/privateKK";
import { staaten } from "@ttc_types/staaten";
import { staatsangehoearigkeit } from "@ttc_types/staatsangehoerigkeit";
import {
  Berufserfahrung,
  BeschaeftigungLaufendesJahr,
  Profile,
  Projekt,
  SED,
  Toggle,
  User,
} from "@ttc_types/types";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";
import { CanComponentDeactivate } from "src/app/deactivation.guard";
import { AuthService } from "src/app/shared/auth.service";
import {
  getLaufendeTage,
  isRelevantForKFB,
  LaufendesJahrReturn,
} from "src/app/shared/functions";
import {
  ab_nv,
  getAgbForm,
  getCurrentBeschaeftigungsModalData,
  getPersForm,
  getSedForm,
  laufendes_jahr,
} from "./sedPersForm";

@Component({
  selector: "app-apply",
  templateUrl: "./apply.component.html",
  styleUrls: ["./apply.component.scss"],
  animations: [
    trigger("fade", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(100, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(100, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ApplyComponent implements OnInit, CanComponentDeactivate {
  math = Math;

  shirtArr = shirtArr;

  ab_nv = ab_nv;

  laufendes_jahr = laufendes_jahr;

  currentYear: string = dayjs().year().toString();

  public approve_laufendes_jahr = false;

  public haarfarben = haarfarben;

  public persForm!: UntypedFormGroup;

  public laufendesJahrFG!: UntypedFormGroup;

  public sprachenFG!: UntypedFormGroup;

  public sedForm!: UntypedFormGroup;

  public agbForm!: UntypedFormGroup;

  public showBewerbungsstrecke: boolean = true;

  public tage_beschaeftigt_control: number | null = null;

  public infoModals = {
    experiences: false,
    insurance: false,
    status: false,
    taxId: false,
    taxClass: false,
  };

  get getStaatsstaatsangehoearigkeit() {
    return staatsangehoearigkeit;
  }

  get getStaaten() {
    return staaten;
  }

  fotoGuidelinesOpen: boolean = false;

  gesetzlicheKK = gesetzlicheKK;

  privateKK = privateKK;

  get getKK() {
    const typ = this.persForm.value.krankenkasse_typ;
    if (typ == "gesetzlich") {
      return this.gesetzlicheKK;
    }
    if (typ == "privat") {
      return this.privateKK;
    }
    return [];
  }

  get getKonfessionen() {
    return konfessionen;
  }

  btnTxt = "Weiter zur Sedcard";

  shirtToggles = shirtToggles;

  geschlecht = geschlecht;

  jacke_sacko_groessen = jacke_sacko_groessen;

  hose_rock_groessen = hose_rock_groessen;

  jeansweite = jeansweite;

  jeanslaenge = jeanslaenge;

  schuhgroesse = schuhgroesse;

  guertellaenge = guertellaenge;

  kragenweite = kragenweite;

  steuerKlasseToggles: Toggle[] = steuerKlassen.map((el: number) => {
    return { label: el };
  });

  sprachToggles: Toggle[] = sprachNiveau.map((el: string) => {
    return { label: el };
  });

  getSprachToggleIndex(level: string) {
    return this.sprachToggles.findIndex((el) => el.label == level);
  }

  stickyBtnDisabled: boolean = false;

  addBerufserfahrungModal: boolean = false;

  openLoginModal: boolean = false;

  public loginButtonDisabled: boolean = false;

  public loginButtonLoading: boolean = false;

  public loginForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl("", [Validators.required]),
    password: new UntypedFormControl("", [Validators.required]),
  });

  isTopteamer: boolean = true;

  laufendesJahrCheckBoxes: UntypedFormGroup = new UntypedFormGroup({});

  laufendesJahrCheckBoxesSubscription: Subscription | null = null;

  laufendesJahrTage: LaufendesJahrReturn[] = [];

  currentAddLanguageString: string = "";

  isUploading: boolean = false;

  addWeitereSprache() {
    if (this.currentAddLanguageString?.length) {
      const tmpValue = this.sedForm.get("sprachen")?.get("weitere")?.value;
      tmpValue.push({
        label: this.currentAddLanguageString,
        level: "Nein",
      });
      this.currentAddLanguageString = "";
      this.sedForm.get("sprachen")?.get("weitere")?.setValue(tmpValue);
    }
  }

  isSedEditFormOnly: boolean = false;

  isPersEditFormOnly: boolean = false;

  isRegisterOnly: boolean = false;

  public projektLeiterNotTopteamer: boolean = false;

  @HostBinding("class.padding-bottom-160")
  paddingBottom160: boolean = false;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public location: Location,
    private route: ActivatedRoute,
    public data: DataService,
    public router: Router,
    private cdf: ChangeDetectorRef,
    private auth: AuthService
  ) {}

  emailExistValidator: AsyncValidatorFn = (
    control: AbstractControl
  ): Promise<ValidationErrors | null> => {
    return new Promise<ValidationErrors | null>((res) => {
      this.data.checkIfEmailExists(control.value).then((emailExists) => {
        res(emailExists ? ({ emailExists } as ValidationErrors) : null);
      });
    });
  };

  enableDisableKragenweite() {
    if (this.persForm.controls.geschlecht.value === "w" || !this.isTopteamer) {
      this.sedForm.controls.kragenweite.disable({ emitEvent: false });
    } else {
      if (
        this.persForm.controls.geschlecht.value === "m" &&
        this.sedForm.controls.kragenweite.value === 0
      ) {
        this.sedForm.controls.kragenweite.patchValue("", {
          emitEvent: false,
        });
      }
      this.sedForm.controls.kragenweite.enable({ emitEvent: false });
    }
  }

  async initFormSubscriptions() {
    if (this.persFormSubscription) this.persFormSubscription.unsubscribe();
    this.persFormSubscription = this.persForm.valueChanges.subscribe(() => {
      if (this.persForm.value.gesundheitszeugnis === true) {
        this.persForm.controls.gesundheitszeugnis_nachweis.enable({
          emitEvent: false,
        });
      } else {
        this.persForm.controls.gesundheitszeugnis_nachweis.disable({
          emitEvent: false,
        });
      }

      this.enableDisableKragenweite();
    });

    // wohnland subscription for sozialversicherungsnummer
    if (this.wohnlandSubscription) this.wohnlandSubscription.unsubscribe();
    this.wohnlandSubscription =
      this.persForm.controls.wohnland.valueChanges.subscribe((val) => {
        if (val == "CH" && this.isTopteamer) {
          this.persForm.controls.sozialversicherungsnummer.setValue(
            this.persForm.value.steueridentifikationsnummer,
            { emitEvent: false }
          );
          this.persForm.controls.sozialversicherungsnummer.disable({
            emitEvent: false,
          });
        } else if (this.isTopteamer) {
          this.persForm.controls.sozialversicherungsnummer.enable({
            emitEvent: false,
          });
        }
        this.persForm.controls.steueridentifikationsnummer.updateValueAndValidity();
      });
  }

  async initForms(
    use_already_existing_inputs: boolean = false,
    use_saved_api_inputs: boolean = false
  ) {
    let persFormVal;
    let sedFormVal;
    let agbFormVal = null;

    if (use_already_existing_inputs) {
      persFormVal = this.persForm.value;
      sedFormVal = this.sedForm.value;
      agbFormVal = this.agbForm.value;
    }

    this.persForm = getPersForm(
      this.isTopteamer,
      this.laufendesJahrCheckBoxes,
      this.auth.isLoggedIn,
      this.auth.currentUser?.registration_status == "erledigt" &&
        this.auth.currentUser?.role == "regular"
    );

    if (!this.auth.isLoggedIn) {
      this.persForm.controls.email.addAsyncValidators(this.emailExistValidator);
    }

    this.laufendesJahrFG = this.persForm.controls
      .laufendes_jahr as UntypedFormGroup;

    this.sedForm = getSedForm(this.isTopteamer);

    this.sprachenFG = this.sedForm.controls.sprachen as UntypedFormGroup;

    this.agbForm = getAgbForm(this.isTopteamer);

    // do this before patchValue to get triggered by actual values
    this.initFormSubscriptions();

    if (this.laufendesJahrCheckBoxesSubscription)
      this.laufendesJahrCheckBoxesSubscription.unsubscribe();
    this.laufendesJahrCheckBoxesSubscription =
      this.laufendesJahrCheckBoxes.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]) => {
          this.laufendesJahrCheckBoxesSubscriptionFunction(prev, next);
        });

    // first use the API saved inputs and put the actual inputs on top
    if (use_saved_api_inputs) {
      let sedCard;
      let profile = null;
      if (this.auth.isLoggedIn) {
        try {
          [sedCard, profile] = await Promise.all([
            this.data.getSedCard(),
            this.data.getProfile(),
          ]);

          this.recursivelyDeleteEmptyInputs(sedCard);
          this.recursivelyDeleteEmptyInputs(profile);
          this.persForm.patchValue(profile);
          this.sedForm.patchValue(sedCard);

          if (profile?.laufendes_jahr) {
            for (const key in profile.laufendes_jahr) {
              if (profile.laufendes_jahr[key]?.length) {
                this.laufendesJahrCheckBoxes.controls[key].setValue(
                  profile.laufendes_jahr[key]
                );
              }
            }
          }
        } catch (error) {
          console.error(`error`, error);
        }
      }
    }

    if (use_already_existing_inputs) {
      this.recursivelyDeleteEmptyInputs(persFormVal);
      this.recursivelyDeleteEmptyInputs(sedFormVal);
      this.recursivelyDeleteEmptyInputs(agbFormVal);
      if (persFormVal.laufendes_jahr) {
        for (const key in persFormVal.laufendes_jahr) {
          if (persFormVal.laufendes_jahr[key]?.length) {
            this.laufendesJahrCheckBoxes.controls[key].setValue(
              persFormVal.laufendes_jahr[key]
            );
          }
        }
      }
      this.persForm.patchValue(persFormVal);
      this.sedForm.patchValue(sedFormVal);
      this.agbForm.patchValue(agbFormVal);
    }

    this.laufendesJahrCheckBoxesSubscriptionFunction(
      null,
      this.laufendesJahrCheckBoxes.value
    );

    if (this.isSedEditFormOnly) {
      this.persForm.disable();
      this.agbForm.disable();
    }
    if (this.isPersEditFormOnly) {
      this.sedForm.disable();
      this.agbForm.disable();
    }

    this.enableDisableKragenweite();

    this.cdf.detectChanges();
  }

  laufendesJahrCheckBoxesCopy: any = undefined;

  laufendesJahrCheckBoxesSubscriptionFunction(prev: any, next: any) {
    if (!this.laufendesJahrCheckBoxesCopy?.Keine && next.Keine) {
      this.laufendesJahrTage = [];
      for (const key of Object.keys(next)) {
        if (key != "Keine") {
          this.laufendesJahrCheckBoxes.controls[key].setValue(false, {
            emitEvent: false,
          });
          this.laufendesJahrFG.controls[key].disable();
        }
      }
      this.laufendesJahrCheckBoxesCopy = {
        ...this.laufendesJahrCheckBoxes.value,
      };
      return;
    }

    this.laufendesJahrFG.controls.Keine.disable();
    this.laufendesJahrCheckBoxes.controls.Keine.setValue(false, {
      emitEvent: false,
    });
    for (const key of Object.keys(next)) {
      if (next[key]) {
        this.laufendesJahrFG.controls[key].enable();
      } else {
        this.laufendesJahrFG.controls[key].disable();
      }
    }
    this.updatelaufendesJahrTage();
    this.laufendesJahrCheckBoxesCopy = {
      ...this.laufendesJahrCheckBoxes.value,
    };
  }

  recursivelyDeleteEmptyInputs(formData: { [key: string]: any }) {
    const tmpFormData = formData;
    if (!tmpFormData) return;
    for (const key of Object.keys(tmpFormData)) {
      if (tmpFormData[key] !== false && tmpFormData[key] !== 0) {
        if (!tmpFormData[key] || tmpFormData[key] == "") {
          delete tmpFormData[key];
        }
        if (typeof tmpFormData[key] === "object") {
          this.recursivelyDeleteEmptyInputs(tmpFormData[key]);
        }
      }
    }
  }

  updatelaufendesJahrTage() {
    this.laufendesJahrTage = getLaufendeTage(this.laufendesJahrFG.value, [
      this.currentYear,
    ]);
  }

  public currentProject: Projekt | undefined;

  public submitAttempt: boolean = false;

  public formSaved: boolean = false;

  // arbeitsbeginn status valid array
  async canDeactivate(): Promise<boolean> {
    if (this.formSaved) {
      return true;
    }
    const res: boolean = await new Promise((resolve) => {
      Confirm.show(
        !this.isSedEditFormOnly && !this.isPersEditFormOnly
          ? "Zurück zu Jobs"
          : "Zurück zur Übersicht",
        "Bist du dir sicher? Deine bisher eingegebenen Daten gehen verloren.",
        "Nein",
        "Ja",
        () => {
          resolve(false);
        },
        () => {
          resolve(true);
        }
      );
    });
    return res;
  }

  navigateBack() {
    this.location.back();
  }

  collapsedBg: string[] = [
    `linear-gradient(0deg, rgba(60, 75, 93, 0.3), rgba(60, 75, 93, 0.2)), #FFFFFF`,
    `linear-gradient(0deg, rgba(60, 75, 93, 0.2), rgba(60, 75, 93, 0.1)), #FFFFFF`,
    `linear-gradient(0deg, rgba(60, 75, 93, 0.1), rgba(60, 75, 93, 0.1)), #FFFFFF`,
  ];

  hoechster_allgemeiner_schulabschluss = hoechster_allgemeiner_schulabschluss;

  hoechster_beruflicher_abschluss = hoechster_beruflicher_abschluss;

  getCollapsedBg(i: number) {
    return this.collapsedBg.slice(-this.currentlyOpenCard)[i - 1];
  }

  scrollToTop() {
    setTimeout(() => {
      try {
        const element = document.getElementById("apply-scroll-target");
        element?.scrollIntoView({
          behavior: "smooth",
        });
      } catch (error) {
        console.error(`scrollIntoView error`, error);
      }
    }, 1000);
  }

  currentlyOpenCard = 1;

  logInvalidFormFields(form: UntypedFormGroup) {
    const problems: { key: string; value: unknown }[] = [];

    for (const key in form.controls) {
      if (form.controls[key].invalid) {
        problems.push({ key, value: form.controls[key].value });
      }
    }

    console.error(`validation errors in the form found:`);
    console.table(problems);
  }

  async ctaClicked(card: number | null = null) {
    if (this.isUploading) {
      Notify.warning("Es werden noch Dateien hochgeladen. Bitte warten.");
      return;
    }

    // isPersEditFormOnly & isSedEditFormOnly is for profile editing /not register
    if (this.isPersEditFormOnly) {
      if (this.persForm.invalid) {
        this.submitAttempt = true;
        this.logInvalidFormFields(this.persForm);
        this.goToInvalidForm();
        return;
      }
    }

    if (this.isSedEditFormOnly) {
      if (this.sedForm.invalid) {
        this.submitAttempt = true;
        this.logInvalidFormFields(this.sedForm);
        this.goToInvalidForm();
        return;
      }
    }

    if (this.isPersEditFormOnly || this.isSedEditFormOnly) {
      try {
        await this.data.updateUserProfile(
          this.isPersEditFormOnly
            ? (this.persForm.value as Profile)
            : undefined,
          this.isSedEditFormOnly ? (this.sedForm.value as SED) : undefined
        );
        await this.auth.setUserProfile();
        this.formSaved = true;
      } catch (error) {
        Notify.failure("Etwas ist schief gelaufen.");
        console.error(`error`, error);
        return;
      }

      Notify.success(
        `${
          this.isPersEditFormOnly ? "Das Profil" : "Die Sedcard"
        } wurde erfolgreich aktualisiert.`
      );

      // updateProfile
      if (this.projektLeiterNotTopteamer) {
        this.router.navigateByUrl("meine-projekte");
      } else {
        this.router.navigateByUrl("daten");
      }
      return;
    }

    if (card) {
      this.currentlyOpenCard = card - 1;
    }

    this.submitAttempt = false;

    if (this.currentlyOpenCard == 0) {
      this.currentlyOpenCard = 1;
      this.scrollToTop();

      this.btnTxt = "Weiter zur Sedcard";
    } else if (this.currentlyOpenCard == 1) {
      if (this.persForm.invalid) {
        this.submitAttempt = true;
        this.logInvalidFormFields(this.persForm);
        this.goToInvalidForm();
        return;
      }
      this.currentlyOpenCard = 2;
      this.scrollToTop();

      if (!this.auth.isLoggedIn) {
        this.btnTxt = "Eingaben bestätigen";
      } else {
        this.btnTxt = "Eingaben überprüfen";
      }
    } else if (this.currentlyOpenCard == 2) {
      if (this.persForm.invalid || this.sedForm.invalid) {
        this.goToInvalidForm();
        return;
      }
      if (this.isTopteamer && !this.agbForm.valid) {
        this.goToInvalidForm();
        return;
      }
      if (this.isTopteamer) {
        // eingaben bestätigen
        // tage gearbeitet modal nochmals zeigen

        // enable the formgroup for 'KurzfristigeBeschaeftigung'
        this.laufendesJahrCheckBoxes.controls.KurzfristigeBeschaeftigung.setValue(
          true,
          { emitEvent: false }
        );
        this.laufendesJahrFG.controls.KurzfristigeBeschaeftigung.enable();

        this.approve_laufendes_jahr = true;
        return;
        // abschicken.
      }

      this.currentlyOpenCard = 3;
      this.scrollToTop();

      this.btnTxt = "Bewerbung abschicken";
    } else if (this.currentlyOpenCard == 3) {
      if (
        this.persForm.invalid ||
        this.sedForm.invalid ||
        this.agbForm.invalid
      ) {
        this.goToInvalidForm();
        return;
      }

      // überprüfen und abschicken
      this.stickyBtnDisabled = true;
      // register
      try {
        // login
        if (!this.auth.isLoggedIn) {
          const registerRes = await this.auth.signUp({
            email: this.persForm.get("email")?.value,
            password: this.persForm.get("password")?.value,
            agb: this.agbForm.get("agb")?.value,
            newsletter: this.agbForm.get("newsletter")?.value,
          } as User);
          if (!registerRes) {
            this.stickyBtnDisabled = false;
            this.openLogin(true);
            return;
          }
        }

        const body = {
          user_id: this.auth.currentUser?.id,
          profile: this.persForm.value as Profile,
          agb: this.agbForm.value,
          sed: this.sedForm.value as SED,
          project: this.currentProject,
          register_only: this.isRegisterOnly,
        };

        // apply unregistered
        const applyResult = await this.data.apply(body);
        if (!applyResult) {
          this.stickyBtnDisabled = false;
          return;
        }

        this.formSaved = true;
        if (this.isRegisterOnly) {
          Notify.success(
            "Die Registrierung war erfolgreich. Du bist nun eingeloggt."
          );
        }
        // redirect to meine jobs
        if (this.isRegisterOnly) {
          this.router.navigateByUrl("/jobs");
        } else {
          this.router.navigateByUrl("/meine-jobs");
        }
      } catch (error) {
        console.error("this.data.apply err", error);
        Notify.failure("Leider ist etwas schief gelaufen. Versuche es erneut.");
      }
      this.stickyBtnDisabled = false;
    }
  }

  loginModalTitle: string =
    "Ein Account mit dieser E-Mail-Adresse existiert bereits";

  loginModalSubTitle: string = "Möchtest du dich einloggen?";

  emailAlreadyExists: boolean = false;

  openLogin(emailExists: boolean = false) {
    this.openLoginModal = true;
    if (emailExists) {
      this.loginForm.controls.email.setValue(this.persForm.value.email);
      this.loginModalTitle =
        "Ein Account mit dieser E-Mail-Adresse existiert bereits";
      this.loginModalSubTitle = "Möchtest du dich einloggen?";
      this.emailAlreadyExists = true;
    } else {
      this.loginModalTitle = "Login";
      this.loginModalSubTitle = "Bitte logge dich ein.";
      this.emailAlreadyExists = false;
    }
  }

  agbText: string =
    "Topteam GmbH darf meine Daten im Rahmen des Bewerbungsprozessses an Auftraggeber weitergeben.";

  newsletterText: string =
    "Ich bin damit einverstanden auch für zukünftige Events informiert zu werden.";

  goToInvalidForm() {
    this.submitAttempt = true;
    Notify.warning("Es gibt noch Felder welche ausgefüllt werden müssen");
    if (this.persForm.invalid && !this.isSedEditFormOnly) {
      this.currentlyOpenCard = 1;
      this.btnTxt = "Weiter zur Sedcard";
    } else if (this.sedForm.invalid) {
      this.currentlyOpenCard = 2;
      if (this.isTopteamer) {
        this.btnTxt = "Eingaben bestätigen";
      } else {
        this.btnTxt = "Eingaben überprüfen";
      }
    } else if (this.agbForm.invalid) {
      this.currentlyOpenCard = this.isTopteamer ? 2 : 3;
      this.btnTxt = "Bewerbung abschicken";
    }

    // scroll to first .alert
    setTimeout(() => {
      try {
        const alertElements = document.getElementsByClassName("alert");
        if (alertElements?.length) {
          alertElements[0].parentElement?.scrollIntoView({
            behavior: "smooth",
            // block: "end",
          });
        }
      } catch (error) {
        console.error(`scrollIntoView error`, error);
      }
    }, 500);
  }

  // login start
  closeLogin() {
    this.openLoginModal = false;
    if (this.emailAlreadyExists) {
      this.persForm.patchValue({
        email: null,
      });
      this.goToInvalidForm();
    }
  }

  loginError: string = "";

  async login() {
    this.loginError = "";
    this.loginButtonDisabled = true;
    this.loginButtonLoading = true;
    try {
      const sign_in_res = await this.auth.signIn(this.loginForm.value, true);
      if (this.isRegisterOnly) {
        this.router.navigateByUrl("/meine-jobs");
        return;
      }
      if (this.showBewerbungsstrecke) {
        window.location.reload();
      }
      // update form on non dirty formControls
      if (!sign_in_res) {
        this.loginError = "Bitte überprüfe deine Eingabe.";
        this.loginButtonDisabled = false;
        this.loginButtonLoading = false;
        return;
      }
      if (this.emailAlreadyExists) {
        this.initForms();
        this.showBewerbungsstrecke = false;
        return;
      }
    } catch (error) {
      console.error(error);
      return;
    }
    if (this.emailAlreadyExists) {
      try {
        // apply
        const apply_res = await this.data.apply({
          user_id: this.auth.currentUser?.id,
          profile: this.persForm.value as Profile,
          agb: this.agbForm.value,
          sed: this.sedForm.value as SED,
          project: this.currentProject,
        });

        if (!apply_res) {
          this.router.navigateByUrl("/jobs");
        } else {
          this.router.navigateByUrl("/meine-jobs");
        }
      } catch (error) {
        console.error("this.data.apply err", error);
        this.router.navigateByUrl("/jobs");
        Notify.failure("Leider hat das nicht geklappt");
      }
    } else {
      this.showBewerbungsstrecke = false;
      this.initForms();
    }

    this.loginButtonDisabled = false;
    this.loginButtonLoading = false;
    this.openLoginModal = false;
  }

  // login end

  // Beschaeftigung start

  currentBeschaeftigungModalFG: UntypedFormGroup =
    getCurrentBeschaeftigungsModalData();

  initCurrentBeschaeftigungModalFG() {
    this.currentBeschaeftigungModalFG = getCurrentBeschaeftigungsModalData();
  }

  addBeschaeftigungModal: boolean = false;

  currentBeschaeftigungModalFGSubscription: Subscription | undefined;

  startMinYear: string | null = null;

  startMaxYear: string | null = null;

  endMinYear = `${dayjs().year()}-01-01`;

  endMaxYear = `${dayjs().year()}-12-31`;

  openAddBeschaeftigung(currentBeschaeftigung: any, key: string) {
    this.modalSubmitAttempt = false;
    this.initCurrentBeschaeftigungModalFG();
    if (currentBeschaeftigung) {
      this.currentBeschaeftigungModalFG.patchValue(currentBeschaeftigung);
    } else {
      this.currentBeschaeftigungModalFG = getCurrentBeschaeftigungsModalData();
      this.currentBeschaeftigungModalFG.controls.arbeitgeber.setValue("");
      this.currentBeschaeftigungModalFG.controls.formControlName.setValue(key);
      this.currentBeschaeftigungModalFG.controls.label.setValue(
        this.laufendes_jahr[key].label
      );
    }
    if (key == "Praktikum") {
      this.currentBeschaeftigungModalFG.controls.praktikum_art.enable();
    } else {
      this.currentBeschaeftigungModalFG.controls.praktikum_art.disable();
    }

    this.addBeschaeftigungModal = true;
    this.currentBeschaeftigungModalFGSubscription =
      this.currentBeschaeftigungModalFG.valueChanges.subscribe((value) => {
        const startTime = value.zeitraum_von ?? "";
        const splitStart = startTime.split("-");
        if (value.zeitraum_von && splitStart.length < 3) {
          const [year] = splitStart;

          // clear zeitraum_von if year is not current year
          if (parseInt(year, 10) !== dayjs().year()) {
            this.currentBeschaeftigungModalFG.controls.zeitraum_von.setValue(
              ""
            );
          }
        }

        const endTime = value.zeitraum_bis ?? "";
        const splitEnd = endTime.split("-");
        if (!value.zeitraum_bis || splitEnd.length < 3) {
          return;
        }

        const [year] = splitEnd;

        // clear zeitraum_bis if year is not current year
        if (parseInt(year, 10) !== dayjs().year()) {
          this.currentBeschaeftigungModalFG.controls.zeitraum_bis.setValue("");
        }
      });
  }

  public approveLaufendesJahrError = false;

  async approveLaufendesJahr() {
    try {
      if (
        this.tage_beschaeftigt_control !==
        this.tage_beschaeftigt_in_current_year
      ) {
        this.submitAttempt = true;
        this.approveLaufendesJahrError = true;
        Notify.failure(
          "Die Anzahl der gearbeiteten Tage stimmt nicht mit den eingetragenen Tagen überein."
        );
        return;
      }

      this.approve_laufendes_jahr = false;
      this.approveLaufendesJahrError = false;

      // apply
      this.formSaved = true;
      const applyResult = await this.data.apply({
        user_id: this.auth.currentUser?.id || 0,
        profile: this.persForm.value as Profile,
        agb: this.agbForm.value,
        sed: this.sedForm.value as SED,
        project: this.currentProject,
      });
      if (!applyResult) {
        this.router.navigateByUrl("/jobs");
        return;
      }
      this.router.navigateByUrl("/meine-jobs");
    } catch (error) {
      console.error("this.data.apply err", error);
      Notify.failure("Leider hat das nicht geklappt");
    }
  }

  closeBeschaeftigung() {
    this.initCurrentBeschaeftigungModalFG();
    this.modalSubmitAttempt = false;
    this.addBeschaeftigungModal = false;
    if (this.currentBeschaeftigungModalFGSubscription) {
      this.currentBeschaeftigungModalFGSubscription.unsubscribe();
    }
  }

  validateTageBeschaeftigt() {
    if ((this.currentBeschaeftigungModalFG.value.tage_beschaeftigt || 0) < 1) {
      this.currentBeschaeftigungModalFG.controls.tage_beschaeftigt.setValue(1);
    }
  }

  incrementTageBeschaeftigt(increment: boolean = true) {
    if (increment) {
      this.currentBeschaeftigungModalFG.controls.tage_beschaeftigt.setValue(
        (this.currentBeschaeftigungModalFG.value.tage_beschaeftigt || 0) + 1
      );
    } else {
      this.currentBeschaeftigungModalFG.controls.tage_beschaeftigt.setValue(
        Math.max(
          (this.currentBeschaeftigungModalFG.value.tage_beschaeftigt || 0) - 1,
          1
        )
      );
    }
  }

  modalSubmitAttempt: boolean = false;

  saveCurrentBeschaeftigung() {
    this.modalSubmitAttempt = true;
    if (this.currentBeschaeftigungModalFG.invalid) return;
    const key = this.currentBeschaeftigungModalFG.value.formControlName;
    const tmpBeschaeftigung = this.laufendesJahrFG.value[key] || [];
    tmpBeschaeftigung.push(
      this.currentBeschaeftigungModalFG.value as BeschaeftigungLaufendesJahr
    );
    this.laufendesJahrFG.controls[key].setValue(tmpBeschaeftigung);

    this.modalSubmitAttempt = false;
    this.addBeschaeftigungModal = false;

    this.updatelaufendesJahrTage();
  }

  removeBeschaeftigung(key: string, i: number) {
    const tmp = this.laufendesJahrFG.value[key];
    if (tmp?.length) {
      tmp.splice(i, 1);
      this.laufendesJahrFG.controls[key].setValue(tmp, { emitEvent: false });
    }
    this.updatelaufendesJahrTage();
  }

  // Beschaeftigung end

  // Berufserfahrung start
  currentBerufserfahrung: UntypedFormGroup = new UntypedFormGroup({
    veranstaltung: new UntypedFormControl("", [Validators.required]),
    taetigkeit: new UntypedFormControl("", [Validators.required]),
    markenname: new UntypedFormControl("", [Validators.required]),
  });

  openAddArbeitserfahrung(berufserfahrung: Berufserfahrung | null = null) {
    this.modalSubmitAttempt = false;
    if (berufserfahrung) {
      this.currentBerufserfahrung.patchValue(berufserfahrung);
    } else {
      this.currentBerufserfahrung.reset();
    }
    this.addBerufserfahrungModal = true;
  }

  saveCurrentArbeitserfahrung() {
    this.modalSubmitAttempt = true;
    if (this.currentBerufserfahrung.invalid) {
      this.modalSubmitAttempt = true;
      return;
    }
    const tmpBerufserfahrung = this.sedForm.get("berufserfahrung")?.value || [];
    this.sedForm.patchValue({
      berufserfahrung: tmpBerufserfahrung.concat([
        this.currentBerufserfahrung.value,
      ]),
    });
    this.addBerufserfahrungModal = false;
    this.modalSubmitAttempt = false;
  }

  closeArbeitsErfahrung() {
    this.currentBerufserfahrung.reset();
    this.modalSubmitAttempt = false;
    this.addBerufserfahrungModal = false;
  }

  removeBerufserfahrung(i: number) {
    const tmp = this.sedForm.get("berufserfahrung")?.value;
    if (tmp?.length) {
      tmp.splice(i, 1);
      this.sedForm.patchValue({
        berufserfahrung: tmp,
      });
    }
  }
  // Berufserfahrung end

  get laufendes_jahr_array() {
    return Object.values(this.laufendes_jahr);
  }

  get laufendes_jahr_enabled_FG_keys() {
    return Object.keys(this.laufendesJahrFG.controls).filter(
      (key) => key != "Keine" && !this.laufendesJahrFG.controls[key].disabled
    );
  }

  filterLaufendesJahrFGByYear(ljFG: any[]) {
    return ljFG.filter((el) =>
      this.currentYear.includes(dayjs(el.zeitraum_von).year().toString())
    );
  }

  get beschaeftigungFormatted() {
    try {
      const result = Object.keys(this.persForm.value.arbeitsbeginn_status)
        .filter((key) => this.persForm.value.arbeitsbeginn_status[key] === true)
        .map((key) => {
          return `<b>${this.ab_nv[key].label}</b> ${
            this.persForm.value?.arbeitsbeginn_status_additional?.[
              `${key}_fach`
            ] || ""
          }`;
        });
      return result;
    } catch (error) {
      console.error(`beschaeftigungFormatted error`, error);
      return [];
    }
  }

  get tage_beschaeftigt_in_current_year() {
    const entry = this.laufendesJahrTage.find(
      (el) => el.year === this.currentYear
    );
    return entry?.tage_beschaeftigt ?? 0;
  }

  isRelevantJob(job: BeschaeftigungLaufendesJahr) {
    const year = dayjs().year().toString();
    return isRelevantForKFB(job, year);
  }

  persFormSubscription: Subscription | undefined;

  wohnlandSubscription: Subscription | undefined;

  async ngOnInit() {
    // check if topteamer
    this.privateKK.sort((a: any, b: any) => a.localeCompare(b));
    this.gesetzlicheKK.sort((a: any, b: any) => a.localeCompare(b));

    if (this.auth.isLoggedIn) {
      await this.auth.setUserProfile();
    }

    if (this.router.url == "/daten/sed") {
      this.isSedEditFormOnly = true;
      this.currentlyOpenCard = 2;
    }
    if (this.router.url == "/daten/persoenlich") {
      this.isPersEditFormOnly = true;
    }
    if (this.router.url == "/register") {
      this.isRegisterOnly = true;
    }
    if (this.isPersEditFormOnly || this.isSedEditFormOnly) {
      this.btnTxt = "Daten speichern";
      this.paddingBottom160 = true;
    }

    this.isTopteamer = !!this.auth.currentUser?.is_topteamer;
    this.showBewerbungsstrecke = !this.auth.isLoggedIn && !this.isRegisterOnly;

    // 2022-11-03
    // If a Projektleitung is newly created, then he must fill out the SED card before he can do anything else.
    this.projektLeiterNotTopteamer =
      !this.auth.currentUserFnc?.is_topteamer &&
      this.auth.currentUserFnc?.role == "pm";

    this.initForms(false, this.auth.isLoggedIn);

    const routeParams = this.route.snapshot.paramMap;
    const projekt_id = routeParams.get("id")
      ? Number(routeParams.get("id"))
      : null;
    if (projekt_id) {
      this.currentProject = await this.data.getProjectById(projekt_id, false);
    }

    // project years
    if (
      this.currentProject &&
      this.currentProject.zeitraum_von &&
      this.currentProject.zeitraum_bis
    ) {
      const year = dayjs().year();
      this.startMinYear = `${year}-01-01`;
      this.startMaxYear = `${year}-12-31`;
    }
    this.updatelaufendesJahrTage();
  }

  ngOnDestroy() {
    if (this.laufendesJahrCheckBoxesSubscription) {
      this.laufendesJahrCheckBoxesSubscription.unsubscribe();
    }

    if (this.currentBeschaeftigungModalFGSubscription) {
      this.currentBeschaeftigungModalFGSubscription.unsubscribe();
    }

    if (this.wohnlandSubscription) {
      this.wohnlandSubscription.unsubscribe();
    }
    if (this.persFormSubscription) {
      this.persFormSubscription.unsubscribe();
    }
  }
}
