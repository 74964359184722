import { AbstractControl } from "@angular/forms";
import { BeschaeftigungLaufendesJahr, Projekt, User } from "@ttc_types/types";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";

export const sortByStatus = (a: User, b: User) => {
  const statusOrder = [
    "neu",
    "daten_pruefen",
    "warten_auf_daten",
    "projekt_pool", // unentschlossen
    "auswahl_auftraggeber_invisible", // in auswahl für auftraggeber -> sichtbar in link
    "auswahl_auftraggeber_visible", // in auswahl für auftraggeber -> unsichtbar in link
    "auftraggeber_akzeptiert",
    "auftraggeber_abgesagt",
    "vertrag_gegenzeichnen", // admin/ pm muss gegenzeichnen
    "warten_auf_vertrag", // arbeitskraft muss unterzeichnen
    "erledigt_verbindlich", // final - alle haben unterzeichnet;
    // was bei kündigung machen?
    "abgesagt",
    "gekuendigt",
  ];
  return (
    statusOrder.indexOf(a.applications![0].status!) -
    statusOrder.indexOf(b.applications![0].status!)
  );
};

export const sortByStatusAndThenByName = (a: User, b: User) => {
  const statusOrder = [
    "neu",
    "daten_pruefen",
    "warten_auf_daten",
    "projekt_pool", // unentschlossen
    "auswahl_auftraggeber_invisible", // in auswahl für auftraggeber -> sichtbar in link
    "auswahl_auftraggeber_visible", // in auswahl für auftraggeber -> unsichtbar in link
    "auftraggeber_akzeptiert",
    "auftraggeber_abgesagt",
    "vertrag_gegenzeichnen", // admin/ pm muss gegenzeichnen
    "warten_auf_vertrag", // arbeitskraft muss unterzeichnen
    "erledigt_verbindlich", // final - alle haben unterzeichnet;
    // was bei kündigung machen?
    "abgesagt",
    "gekuendigt",
  ];
  const statusA = statusOrder.indexOf(a.applications![0].status!);
  const statusB = statusOrder.indexOf(b.applications![0].status!);
  if (statusA === statusB) {
    const nameComp = (a.profile?.vorname || "").localeCompare(
      b.profile?.vorname || ""
    );
    if (nameComp === 0) {
      return (a.profile?.nachname || "").localeCompare(
        b.profile?.nachname || ""
      );
    }
    return nameComp;
  }
  return statusA - statusB;
};

// TODO: restrict laufendes jahr always to current year of the project.
// TODO: status bei arbeitsbeginn rein?
export type LaufendesJahrReturn = {
  year: string;
  tage_beschaeftigt: number;
  valid: boolean;
  errors: string[];
};

function jobStartedThisYear(job: BeschaeftigungLaufendesJahr, year?: string) {
  // assert if format is correct
  if (year) {
    const yearInt = parseInt(year, 10);
    if (Number.isNaN(yearInt)) {
      throw new Error("Year has invalid format");
    }
  }

  const thisYear = year ?? dayjs().year().toString();

  return dayjs(job.zeitraum_von).year().toString() === thisYear;
}

export function isRelevantForKFB(
  job: BeschaeftigungLaufendesJahr,
  year?: string
) {
  if (!jobStartedThisYear(job, year)) {
    return false;
  }

  return (
    job.label === "KurzfristigeBeschaeftigung" ||
    job.label === "Kurzfristige Beschäftigung"
  );
}

export const getLaufendeTage = (
  laufendesJahr: { [key: string]: BeschaeftigungLaufendesJahr[] },
  yearsToCheck: string[] = [dayjs().year().toString()]
): LaufendesJahrReturn[] => {
  return yearsToCheck.map((year: string) => {
    const res = {
      year,
      tage_beschaeftigt: 0,
      valid: true,
      errors: [],
    } as LaufendesJahrReturn;

    for (const key of Object.keys(laufendesJahr)) {
      const relevantType =
        key == "KurzfristigeBeschaeftigung" || key == "Praktikum";

      if (relevantType && Array.isArray(laufendesJahr[key])) {
        for (const lj of laufendesJahr[key]) {
          if (!isRelevantForKFB(lj, year)) {
            // eslint-disable-next-line no-continue
            continue;
          }

          res.tage_beschaeftigt += lj.tage_beschaeftigt ?? 0;
        }
      }
    }

    if (res.tage_beschaeftigt > 70) {
      res.errors.push(
        `Der Bewerber ist im Jahr ${res.year} ${res.tage_beschaeftigt} beschäftigt.`
      );
      res.valid = false;
    }

    return res;
  });
};

export const getProjectYears = (project: Projekt) => {
  const years = [
    dayjs(project!.zeitraum_von).year(),
    dayjs(project!.zeitraum_bis).year(),
  ].sort((a, b) => a - b);
  if (years[1] - years[0] > 1) {
    for (let year = years[0]; year < years[1]; year++) {
      years.push(year);
    }
    years.sort((a, b) => a - b);
  }
  return [...new Set(years)].map((el) => el.toString());
};

/**
 * Set the state of a form control to enabled or disabled.
 *
 * @param formControl
 * @param state
 * @param emitEvent if the control should emit an event
 */
export function setFormState(
  formControl: AbstractControl<any>,
  state: boolean,
  emitEvent = false
) {
  if (state) {
    formControl.enable({ emitEvent });
  } else {
    formControl.disable({ emitEvent });
  }
}
