import {
  AfterViewInit,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { CheckInQrCodeResult } from "@ttc_types/types";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";

@Component({
  selector: "app-qr-scanner-element",
  templateUrl: "./qr-scanner-element.component.html",
  styleUrls: ["./qr-scanner-element.component.scss"],
})
export class QrScannerElementComponent implements AfterViewInit {
  @Output() qrCodeResultEvent: EventEmitter<CheckInQrCodeResult> =
    new EventEmitter<CheckInQrCodeResult>();

  @ViewChild("scanner", { static: false })
  scanner!: ZXingScannerComponent;

  @ViewChild("deviceSelect", { static: false })
  select!: HTMLSelectElement;

  public enabled = true;

  public devices: MediaDeviceInfo[] = [];

  public qrCodeResult: CheckInQrCodeResult | null = null;

  ngAfterViewInit() {
    this.scanner.askForPermission();
  }

  /**
   * isCheckInQrCodeResult is a type guard that checks if the toBeTestedObject is a CheckInQrCodeResult
   * @param toBeTestedObject
   * @returns
   */
  isCheckInQrCodeResult(
    toBeTestedObject: any
  ): toBeTestedObject is CheckInQrCodeResult {
    return (
      toBeTestedObject?.ttConnectQrCheckinQrCode?.projectId &&
      toBeTestedObject?.ttConnectQrCheckinQrCode?.userId
    );
  }

  /**
   * onQrCodeScan gets triggered when the qrScanner detects a qrCode.
   * It checks if the qrCodeResult is valid, if it is, it will stop the qrScanner and
   * emit the qrCodeResult to the parent component
   */
  onQrCodeScan(scanValue: string) {
    try {
      const parsedScanValue: CheckInQrCodeResult = JSON.parse(scanValue);
      if (this.isCheckInQrCodeResult(parsedScanValue)) {
        this.qrCodeResult = parsedScanValue;
        this.qrCodeResultEvent.emit(this.qrCodeResult);
        this.enabled = false;
      }
    } catch (error) {
      console.error("parsedScanValue error: ", error);
    }
  }

  setDevice(event: Event | string) {
    let deviceId: string;

    if (event instanceof Event) {
      const target = event.target as HTMLSelectElement;
      deviceId = target.value;
    } else {
      deviceId = event;
    }

    const device = this.devices.find((cam) => cam.deviceId === deviceId);

    if (!device) {
      return;
    }

    window.localStorage.setItem("selectedDeviceId", device.deviceId);

    // Wait for the next tick to make sure the scanner is ready, for some reason camera switching doesn't work otherwise
    this.waitForNextTick().then(() => {
      this.scanner.device = device;
    });
  }

  /**
   * camerasFoundHandler gets triggered when the qrScanner detects a camera.
   * It will try to remember the selected camera from the localStorage, if it doesn't exist it will select the first camera
   *
   * @param devices
   */
  camerasFoundHandler(devices: MediaDeviceInfo[]) {
    this.devices = devices;
    const selectedDeviceId = window.localStorage.getItem("selectedDeviceId");
    this.setDevice(selectedDeviceId ?? devices[0].deviceId);
  }

  waitForNextTick() {
    return new Promise((resolve) => {
      setTimeout(resolve, 80);
    });
  }

  log(event: any) {
    console.error(event);
  }

  get selectedDeviceId() {
    return this.scanner?.device?.deviceId;
  }
}
